import React from "react";

import { PageWrapper } from "~components/Core";


import Consultation from '~sections/Book/Consultation'

import ServicesSection from '~sections/agency/Services'
import FooterFive from '~sections/agency/FooterFive'
import Expertise from '~sections/Book/Expertise/Expertise'

export default function Book() {
    return (
        <>
            <PageWrapper innerPage={true}>

                <Consultation />
                <ServicesSection />
                <Expertise />

                <FooterFive />
            </PageWrapper>
        </>
    )
}